<template>
  <v-container>
    <v-card class="px-5 py-3">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :single-select="true"
        :loading="loading"
        :items-per-page="15"
        :options.sync="options"
        :footer-props="footerProps"
        item-key="id"
        show-select
        sort-by="category"
      >
        <template v-slot:item.type="{ item }">
          {{ typeLists[item.type] }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="edit(item)">
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="mr-2"
            color="red darken-2"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-btn color="primary" class="ma-3" @click="createService">
        Добавить
      </v-btn>
    </v-card>
    <v-dialog v-model="dialog" max-width="500px"> </v-dialog>
  </v-container>
</template>

<script>
import { ServiceApiUrls } from "@/services/accommodationRequests/service.api.js";
import toast from "../../../plugins/toast";

export default {
  name: "Tariff",
  data: () => ({
    e1: 1,
    singleSelect: false,
    selected: [],
    dialog: false,
    headers: [
      {
        text: "Название",
        align: "start",
        sortable: false,
        value: "name",
        class: "table-title"
      },
      { text: "Тип", value: "type", class: "table-title" },
      { text: "Цена", value: "price", sortable: false, class: "table-title" },
      {
        text: "Действия",
        value: "actions",
        sortable: false,
        class: "table-title"
      }
    ],
    items: [],
    typeLists: {
      once: "Единоразово",
      day: "Cутки"
    },
    loading: false,
    footerProps: {
      "items-per-page-options": [
        {
          text: "10",
          value: 10
        },
        {
          text: "20",
          value: 20
        },
        {
          text: "30",
          value: 30
        },
        {
          text: "Все",
          value: 99999999999
        }
      ]
    },
    options: {},
    firstWatcherTrigger: false
  }),
  watch: {
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        this.loadRow();
      },
      deep: true
    }
  },
  created() {
    this.loadRow();
  },
  methods: {
    async loadRow() {
      this.loading = true;
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10
      };
      let res = await ServiceApiUrls.getServices(params);
      this.initialize(res.results);
    },
    initialize(dataSet) {
      if (!dataSet) {
        this.loading = false;
        return;
      }
      dataSet.map(el => {
        const obj = {
          id: el.id,
          name: el.name,
          price: el.price,
          type: el.service_type
        };
        this.items.push(obj);
      });
      this.loading = false;
    },
    edit(item) {
      this.$router.push("/service/" + item.id);
    },
    async deleteItem(item) {
      await ServiceApiUrls.deleteService(item.id);
      this.items = [];
      toast.success("Успешно удалено!");
      this.loadRow();
    },
    createService() {
      this.$router.push("/service");
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
